import { Logger } from '@wix/bi-logger-site-members';
import {
  EditorSDK,
  ComponentRef,
  ActionType,
  ComponentDefinition,
  NotificationType,
  PageRef,
} from '@wix/platform-editor-sdk';
import { TFunction } from 'i18next';
import {
  IRequiredComponentsDefinitionMap,
  IRequiredComponentsDefinitionMapKeys,
} from '../components/custom Sign In/types';
import { getCustomSignInDefinition, popupCloseIconButton } from './componentsDefinition';

export const LIGHTBOX_HEIGHT = 649;
export const LIGHTBOX_WIDTH = 514;

const getEditorSdkSource = (editorSDK: EditorSDK) =>
  editorSDK.info.getSdkVersion('token').scriptSrc;

export const panelUrlBuilder = (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  panelName: string,
) => {
  const inWatchMode = process.env.NODE_ENV !== 'production';
  // During yoshi-flow-editor start we want have local rendered settings panel. For prod - we are using static html file.
  const baseUrl = inWatchMode
    ? `https://localhost:3000/settings/${panelName}`
    : `./settings/${panelName}.html`;

  return `${baseUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK,
  )}&componentId=${componentRef.id}`;
};

type ICreatingTheAppProps = {
  editorSDK: EditorSDK;
  appDefinitionId: string;
  componentId: string;
  t: TFunction;
  popup?: PageRef;
};
export const creatingTheApp = async ({
  editorSDK,
  appDefinitionId,
  componentId,
  t,
  popup,
}: ICreatingTheAppProps) => {
  let popupContainer;
  if (!popup) {
    popup = await editorSDK.document.pages.popupPages.addConnected(
      appDefinitionId,
      {
        title: t('popup.title'),
        controllerType: 'popupController',
        popupRole: 'popupContainer',
        definition: {
          data: {
            managingAppDefId: appDefinitionId,
          },
        },
      },
    );
    await editorSDK.components.properties.update(appDefinitionId, {
      componentRef: popup as ComponentRef,
      props: {
        desktop: { popup: { closeOnOverlayClick: false } },
        mobile: { popup: { closeOnOverlayClick: false } },
      },
    });
    popupContainer = (
      await editorSDK.components.getChildren(appDefinitionId, {
        componentRef: popup as ComponentRef,
      })
    )[0];
    await editorSDK.components.layout.update(appDefinitionId, {
      componentRef: popupContainer,
      layout: { width: LIGHTBOX_WIDTH, height: LIGHTBOX_HEIGHT },
    });
  } else {
    popupContainer = (
      await editorSDK.components.getChildren(appDefinitionId, {
        componentRef: popup as ComponentRef,
      })
    )[0];
  }
  const colors = await editorSDK.theme.colors.getAll(appDefinitionId);
  await editorSDK.components.design.update(appDefinitionId, {
    componentRef: popupContainer,
    // @ts-expect-error TODO update the editorSDK and remove designItem
    designItem: {
      background: {
        type: 'BackgroundMedia',
        color: colors.color_11,
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
      },
    },
    design: {
      background: {
        type: 'BackgroundMedia',
        color: colors.color_11,
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
      },
    },
  });
  await editorSDK.components.add(appDefinitionId, {
    // TODO- figure out what is `layoutResponsive`.
    // @ts-expect-error `layoutResponsive` is missing
    componentDefinition: getCustomSignInDefinition(
      appDefinitionId,
      componentId,
      t,
    ),
    pageRef: popupContainer,
  });
  // Since TB doesn't support fixed position outside parents we must put the close within the page container
  await editorSDK.components.add(appDefinitionId, {
    // TODO- figure out what is `layoutResponsive`.
    // @ts-expect-error `layoutResponsive` is missing
    componentDefinition: popupCloseIconButton,
    pageRef: popupContainer,
  });
};

export const rebaseTheApp = async (
  editorSDK: EditorSDK,
  appDefinitionId: string,
  componentId: string,
  t: TFunction,
) => {
  const popup = await editorSDK.document.pages.getCurrent(appDefinitionId);
  const popupContainer = (
    await editorSDK.components.getChildren(appDefinitionId, {
      componentRef: popup as ComponentRef,
    })
  )[0];
  const popupChildrens = await editorSDK.components.getChildren(
    appDefinitionId,
    {
      componentRef: popupContainer,
    },
  );
  const popupChildrensTypes = await Promise.all(
    popupChildrens.map((child) =>
      editorSDK.components.getType(appDefinitionId, { componentRef: child }),
    ),
  );
  const appRefIndex = popupChildrensTypes.findIndex(
    (type) => type === 'platform.components.AppWidget',
  );
  const appRef = popupChildrens[appRefIndex];
  if (!appRef) {
    throw new Error(`Couldn't reset the app, please try again`);
  }
  await editorSDK.document.components.remove(appDefinitionId, {
    componentRef: appRef,
  });
  await creatingTheApp({ editorSDK, appDefinitionId, componentId, t, popup });
};

export const openFieldSettingsPanelWithoutInputType = ({
  appDefinitionId,
  componentRef,
  editorSDK,
}: {
  appDefinitionId: string;
  componentRef: ComponentRef;
  editorSDK: EditorSDK;
}) => {
  editorSDK.editor.openNativeComponentPanel(
    appDefinitionId,
    'settings' as ActionType,
    {
      componentRef,
      panelSectionsDefinition: {
        inputType: 'hidden',
      },
    },
  );
};

// The reason we had to curried the method is that we had to initiate
// the events queue a head of passing the callback and predicate since
// we have their props only within the actually event context
export const getConditionallyDebounceAQueue = (wait: number) => {
  const events: IGetFireNotificationCurriedProps[] = [];
  return (
    callback: (event: IGetFireNotificationCurriedProps) => void,
    predicate: (events: IGetFireNotificationCurriedProps[]) => boolean,
  ) => {
    let timeout: NodeJS.Timeout;
    return (event: IGetFireNotificationCurriedProps) => {
      events.push(event);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (predicate(events)) {
          events.forEach((_event) => callback.call(undefined, _event));
        }
        events.splice(0);
      }, wait);
    };
  };
};

type IGetFireNotificationProps = {
  editorSDK: EditorSDK;
  t: TFunction;
  _requiredComponentsDefinitionMap: IRequiredComponentsDefinitionMap;
  logger: Logger;
};
type IGetFireNotificationCurriedProps = {
  componentLabel: IRequiredComponentsDefinitionMapKeys;
  payload: any;
};
export const getFireNotification = ({
  editorSDK,
  t,
  _requiredComponentsDefinitionMap,
  logger,
}: IGetFireNotificationProps) => async ({
  componentLabel,
  payload,
}: IGetFireNotificationCurriedProps) => {
  if (!_requiredComponentsDefinitionMap[componentLabel]) {
    return;
  }
  const didClicked = await editorSDK.editor.showUserActionNotification(
    'token',
    {
      message: t('notification.title', {
        componentLabel: t(`addElements.titles.${componentLabel}`),
      }),
      type: isEssentialComponent(componentLabel)
        ? ('error' as NotificationType)
        : ('info' as NotificationType),
      link: { caption: t('notification.caption') },
    },
  );
  // In case the user didn't click for having the component back.
  if (!didClicked) {
    return;
  }
  await addElementToWidget(
    editorSDK,
    payload.connections[0].controllerRef,
    componentLabel,
    _requiredComponentsDefinitionMap,
    t,
  );
  if (componentLabel === 'submit') {
    logger.siteMembersCustomLoginRestoreLoginButtonFromErrorMessage({
      hosting: 'editor',
    });
  }
};

export const isEssentialComponent = (componentLabel: string) => {
  const essentialComponents = ['submit', 'email', 'password'];
  return essentialComponents.includes(componentLabel);
};

export const addElementToWidget = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  label: IRequiredComponentsDefinitionMapKeys,
  _requiredComponentsDefinitionMap:
    | IRequiredComponentsDefinitionMap
    | { [key: string]: ComponentDefinition },
  t: TFunction,
) => {
  // The mediaBox is the actually container for all the components and not the root widget component
  const mediaBox = await editorSDK.components.getChildren('token', {
    componentRef: widgetRef,
  });
  const componentDefinition = _requiredComponentsDefinitionMap[label];
  const component = await editorSDK.components.add('token', {
    componentDefinition:
      typeof componentDefinition === 'function'
        ? componentDefinition(t)
        : componentDefinition,
    pageRef: mediaBox[0],
  });
  editorSDK.controllers.connect('token', {
    connectToRef: component,
    controllerRef: widgetRef,
    role: label,
  });
};

export async function controllerAlreadyExists(
  controllerType: string,
  editorSDK: EditorSDK,
  appDefinitionId: string,
) {
  const controllers = await editorSDK.controllers.listAllControllers(
    appDefinitionId,
  );
  for (const controller of controllers) {
    const data = await editorSDK.controllers.getData(
      appDefinitionId,
      controller,
    );
    if (data.type === controllerType) {
      return true;
    }
  }
  return false;
}
