
  var editorScriptEntry = require('/home/builduser/agent00/work/3d3bad259d669c74/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      var editorScriptBuilder = require('@wix/bob-widget-services').editorScriptBuilder;
      if (false) {
        var hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        var newScript = hot(module, editorScriptEntry.editorReady);
      }

      var builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(false ? newScript : editorScriptEntry.editorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/agent00/work/3d3bad259d669c74/src/components/custom Sign In/editor.controller.ts');

      const model_0 = null;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "undefined";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"undefined": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "undefined", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = (...params) => {
          const { appManifestBuilder } = params[0];
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, getWidgetManifest);
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
