// TODO: this file probably can be deleted since Yoshi 5 upgrade and be
// replaced with Settings.tsx & appSettings.ts files but currently we have
// no settings anyway.
import { createWidgetAPI, WidgetEditorAPI } from '@wix/bob-widget-services';
import { EditorSDK } from '@wix/platform-editor-sdk';

/**
 * Creating an internal API for the panel to use
 * Is the `appApi` of `setAppAPI()` (see docs: https://bo.wix.com/wix-docs/client/editor-platform/classic-editor-sdk/editor/editor)
 * */
function createAppAPI(
  editorSDK: EditorSDK,
  appDefinitionId: string,
): WidgetEditorAPI {
  const widgetAPI = createWidgetAPI(editorSDK, appDefinitionId);

  return {
    ...widgetAPI,
  };
}

export default createAppAPI;
